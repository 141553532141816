<template>
  <v-sheet>
    <history v-if="showHistory" @back="showHistory = false" />
    <div v-else>
      <v-row class="pa-3">
        <v-col cols="12" md="8">
          <span class="display-1 font-weight-bold">Ready to Ship</span>
        </v-col>
        <v-col cols="12" md="4" class="d-md-flex justify-end">
          <!-- <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
            depressed
            color="#04BF68"
            dark
            class="mr-2"
            @click="showHistory = true"
          >
            History
          </v-btn> -->
          <!-- <v-select
            :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
            label="Filter"
            dense
            outlined
          /> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mx-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :loading="loading"
            :expanded.sync="expanded"
            :server-items-length="total"
            single-expand
            show-expand
            @pagination="pagination"
          >
            <template v-slot:[`item.total`]="{ item }">
              ${{ item.total }}
            </template>
            <template v-slot:[`item.shipping_cost`]="{ item }">
              ${{ item.shipping_cost }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="width: 200px">Address</td>
                        <td>{{ item.address }} {{ item.sent_to }}</td>
                      </tr>
                      <tr>
                        <td style="width: 200px">Phone Number</td>
                        <td>{{ item.phone }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="width: 200px">Shipping Cost</td>
                        <td>{{ item.shipping_cost }}</td>
                      </tr>
                      <tr>
                        <td>Handling</td>
                        <td>{{ item.handling }}</td>
                      </tr>
                      <tr>
                        <td>Permit</td>
                        <td>{{ item.premit }}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>{{ item.discount }}</td>
                      </tr>
                      <tr>
                        <td>Bank Fee</td>
                        <td>{{ item.payment_fee }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table class="my-4">
                  <template v-slot:default>
                    <thead>
                      <tr class="font-weight-bold">
                        <td>Name</td>
                        <td>Image</td>
                        <td>Price</td>
                        <td>QTY</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                        <td>{{ d.item.plant }}</td>
                        <td>
                          <v-img
                            :src="d.item.image[0]"
                            class="ma-2"
                            width="80"
                            height="80"
                            @click="
                              () => {
                                viewImage = true;
                                urlFile = d.item.image[0];
                              }
                            "
                          />
                        </td>
                        <td>${{ d.item.price }}</td>
                        <td>{{ d.quantity }}</td>
                        <td>${{ d.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:[`item.invoice_packing`]="{ item }">
              <v-btn
                :disabled="loadingUpdate"
                text
                @click="onButtonClick(item, false)"
              >
                <v-icon>mdi-attachment</v-icon>
              </v-btn>
              <v-btn
                :disabled="loadingUpdate"
                :dark="!loadingUpdate"
                color="#04BF68"
                text
                @click="
                  () => {
                    viewImage = true;
                    urlFile = item.invoice_packing;
                  }
                "
              >
                <v-icon>
                  {{ item.invoice_packing ? "mdi-eye" : "mdi-eye-off" }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.phyto`]="{ item }">
              <v-btn
                :disabled="loadingUpdate"
                text
                @click="onButtonClick(item, true)"
              >
                <v-icon>mdi-attachment</v-icon>
              </v-btn>
              <v-btn
                :disabled="loadingUpdate"
                :dark="!loadingUpdate"
                color="#04BF68"
                text
                @click="
                  () => {
                    viewImage = true;
                    urlFile = item.phyto;
                  }
                "
              >
                <v-icon>
                  {{ item.phyto ? "mdi-eye" : "mdi-eye-off" }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.resi`]="{ item }">
              <v-text-field
                v-model="item.resi"
                :disabled="loadingUpdate"
                hide-details
                dense
                outlined
                @blur="updateField(item)"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :disabled="loadingUpdate"
                :dark="!loadingUpdate"
                color="#04BF68"
                fab
                depressed
                x-small
                class="mx-2"
                @click="readyToShip(item.id)"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <input
        v-show="false"
        ref="upload"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      />
      <view-image
        :viewFile="viewImage"
        :urlFile="urlFile"
        @close="viewImage = false"
      />
      <v-dialog
        v-model="dialogShip"
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card flat>
          <v-card-text class="pa-4">
            <div class="mb-5 mx-5 text-center subtitle-1">
              <p class="font-weight-bold title">Congratulation!</p>
              <p>
                Your package delivery proccess has been completed. customers
                will get an email containing purchase details and receipt.
              </p>
            </div>
            <v-divider class="my-2" />
            <v-row>
              <v-col class="d-flex justify-center">
                <!-- <v-col class="d-flex justify-space-between"> -->
                <!-- <v-btn
                  width="150"
                  class="gray"
                  dark
                  depressed
                  @click="dialogShip = false"
                >
                  Back
                </v-btn> -->
                <v-btn
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="dialogShip = false"
                >
                  OK
                </v-btn>
                <!-- <v-btn
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="showHistory = true"
                >
                  History
                </v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-overlay :value="loadingUpload" z-index="100">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </div>
  </v-sheet>
</template>

<script>
import ViewImage from "../../../components/ViewImage.vue";
import History from "./History.vue";
import { getReadyToShip, shipp, updateShipp } from "@/api/admin/order";
import { isURL } from "@/utils/validate";
import { uploadFile, removeFile } from "@/api/file";

export default {
  components: {
    ViewImage,
    History,
  },
  data() {
    return {
      showHistory: false,
      dialogShip: false,
      loading: false,
      loadingUpload: false,
      loadingUpdate: false,
      viewImage: false,
      data: [],
      selectedData: {
        id: "",
        invoice_packing: "",
        phyto: "",
        resi: "",
      },
      deleteData: null,
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: 0,
      total: 0,
      expanded: [],
      uploadIsPhyto: false,
      query: {
        page: 1,
        limit: 10,
      },
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Invoice Packing",
          value: "invoice_packing",
          width: 130,
        },
        {
          text: "Phyto",
          value: "phyto",
          width: 130,
        },
        {
          text: "Resi",
          width: 150,
          value: "resi",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 100,
        },
      ],
      oldImage: "",
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (parseInt(n) === 3) this.getReadyToShip();
    },
  },
  mounted() {
    this.getReadyToShip();
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getReadyToShip();
    },
    onButtonClick({ invoice_packing, phyto, resi, id }, isPhyto) {
      this.uploadIsPhyto = isPhyto;
      this.oldImage = isPhyto ? phyto : invoice_packing;
      this.selectedData = { id, invoice_packing, phyto, resi };
      this.$refs.upload.click();
    },
    async onFileChanged(e) {
      if (e.target.files.length > 0) {
        try {
          this.loadingUpload = true;
          const file = e.target.files[0];

          // Upload image
          if (file) {
            const form = new FormData();
            const { invoice_packing, phyto } = this.selectedData;
            const deleteUrlImage = this.uploadIsPhyto ? phyto : invoice_packing;
            form.append("file", file);

            const uploadImage = await uploadFile(form);
            if (uploadImage.data.code) {
              const path = uploadImage.data.data.path;

              if (this.uploadIsPhyto) this.selectedData.phyto = path;
              else this.selectedData.invoice_packing = path;

              // Delete old image, if exist
              if (isURL(deleteUrlImage)) {
                const delImage = await removeFile({ file: deleteUrlImage });
                if (!delImage.data.code)
                  this.snackBar(false, "Failed delete image");
              }

              // Update data
              this.updateField(this.selectedData);
            } else this.snackBar(false, "Failed upload image");
          }
        } catch (error) {
          console.error("onFIleChanged(e)\n", error);
        } finally {
          this.loadingUpload = false;
        }
      }
    },
    updateField(item) {
      this.loadingUpdate = true;
      const { id, invoice_packing, phyto, resi } = item;
      const body = { id, data_update: { invoice_packing, phyto, resi } };
      updateShipp(body)
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.getReadyToShip();
            this.snackBar(true, message);
          } else this.snackBar(false, message);
        })
        .catch((e) => console.error("updateField(item)\n", e))
        .finally(() => (this.loadingUpdate = false));
    },
    readyToShip(id) {
      this.loading = true;
      shipp({ id })
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.getReadyToShip();
            this.dialogShip = true;
            this.snackBar(true, message);
          } else this.snackBar(false, message);
        })
        .catch((e) => console.error("readyToShip(id)\n", e))
        .finally(() => (this.loading = false));
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getReadyToShip() {
      this.loading = true;
      getReadyToShip(this.query)
        .then((res) => {
          const { code } = res.data;
          if (code) {
            const items = res.data.data.data;
            this.total = res.data.data.total;
            this.data = items.map((e) => {
              const {
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const {
                id,
                subtotal,
                total,
                invoice_packing,
                shipping_cost,
                phyto,
                resi,
                handling,
                premit,
                discount,
                payment_fee,
              } = e;
              return {
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                sent_to: `${city}, ${postal_code}, ${province}, ${country}`,
                items: e.item,
                id,
                subtotal,
                total,
                invoice_packing,
                phyto,
                shipping_cost,
                resi,
                handling,
                premit,
                discount,
                payment_fee,
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
