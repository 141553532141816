<template>
  <v-sheet>
    <v-row class="pa-3">
      <v-col cols="12" md="4" class="d-flex align-center">
        <v-btn depressed class="mr-2" icon @click="back()">
          <v-icon color="primary" x-large>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="display-1 font-weight-bold">History</span>
      </v-col>
      <v-col cols="12" md="4">
        <span class="title font-weight-bold">
          Earning in January 2022: $65
        </span>
      </v-col>
      <v-col cols="12" md="4" class="d-md-flex justify-end">
        <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          :class="!$vuetify.breakpoint.xsOnly && 'select-200'"
          label="Month"
          hide-details
          dense
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mx-2">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          item-key="id"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              :src="item.image"
              class="ma-2"
              width="80"
              height="80"
              style="cursor: pointer"
              @click="
                () => {
                  viewImage = true;
                  urlFile = item.image;
                }
              "
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import ViewImage from "../../../components/ViewImage.vue";

export default {
  components: {
    ViewImage,
  },
  data() {
    return {
      loading: false,
      dialogConfrim: false,
      dialogImage: false,
      dialogVideo: false,
      viewImage: false,
      data: [],
      selectedData: {},
      deleteData: null,
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: 0,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Plant",
          value: "plant",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Quantity",
          value: "qty",
        },
        {
          text: "Sent to",
          value: "sent_to",
        },
        {
          text: "Resi",
          value: "resi",
          width: 200,
        },
        {
          text: "Price (Netto)",
          value: "price",
        },
      ],
    };
  },
  mounted() {
    this.data = [
      {
        name: "Nathan",
        plant: "Alocasia",
        image:
          "https://api.plantiary.com/images/plants/v1/adiantum-raddianum.jpg",
        qty: 10,
        shipping_cost: 0,
        sent_to: "Semarang",
        resi: "18292",
        price: "18292",
      },
      {
        name: "John",
        plant: "Agloenema",
        image:
          "https://api.plantiary.com/images/plants/v1/adiantum-raddianum.jpg",
        qty: 3,
        shipping_cost: 0,
        sent_to: "Jakarta",
        resi: "18291",
        price: "18291",
      },
      {
        name: "Bobby",
        plant: "Alocasia",
        image:
          "https://api.plantiary.com/images/plants/v1/adiantum-raddianum.jpg",
        qty: 4,
        shipping_cost: 0,
        sent_to: "Malang",
        resi: "18329",
        price: "18329",
      },
      {
        name: "Naruto",
        plant: "Alocasia",
        image:
          "https://api.plantiary.com/images/plants/v1/adiantum-raddianum.jpg",
        qty: 2,
        shipping_cost: 0,
        sent_to: "Konoha",
        resi: "18429",
        price: "18429",
      },
      {
        name: "Tenten",
        plant: "Alocasia",
        image:
          "https://api.plantiary.com/images/plants/v1/adiantum-raddianum.jpg",
        qty: 7,
        shipping_cost: 0,
        sent_to: "Konoha",
        resi: "18429",
        price: "18429",
      },
    ];
  },
  methods: {
    back() {
      this.$emit("back");
    },
    btn(data) {
      console.log(data);
    },
  },
};
</script>
