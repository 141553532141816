import request from "@/utils/request";

export function getNewOrder(data) {
  return request({
    method: "post",
    url: "/transaction-admin/new-order",
    data,
  });
}

export function approveOrder(data) {
  return request({
    method: "post",
    url: "/transaction-admin/approve",
    data,
  });
}

export function rejectOrder(data) {
  return request({
    method: "post",
    url: "/transaction-admin/reject",
    data,
  });
}

export function rejectListOrder(data) {
  return request({
    method: "post",
    url: "/transaction-admin/reject-list",
    data,
  });
}

export function getApprove(data) {
  return request({
    method: "post",
    url: "/transaction-admin/approve-list",
    data,
  });
}

export function updateApprove(data) {
  return request({
    method: "post",
    url: "/transaction-admin/update",
    data,
  });
}

export function processApprove(data) {
  return request({
    method: "post",
    url: "/transaction-admin/process",
    data,
  });
}

export function getProccessList(data) {
  return request({
    method: "post",
    url: "/transaction-admin/process-list",
    data,
  });
}

export function processPacking(data) {
  return request({
    method: "post",
    url: "/transaction-admin/process-packing",
    data,
  });
}

export function processPhyto(data) {
  return request({
    method: "post",
    url: "/transaction-admin/process-phyto",
    data,
  });
}

export function setReady(data) {
  return request({
    method: "post",
    url: "/transaction-admin/set-ready",
    data,
  });
}

export function getReadyToShip(data) {
  return request({
    method: "post",
    url: "/transaction-admin/ready-list",
    data,
  });
}

export function shipp(data) {
  return request({
    method: "post",
    url: "/transaction-admin/shipp",
    data,
  });
}

export function updateShipp(data) {
  return request({
    method: "post",
    url: "/transaction-admin/update",
    data,
  });
}

export function rejectProof(data) {
  return request({
    method: "post",
    url: "/transaction-admin/pay-invalid",
    data,
  });
}

export function approveProof(data) {
  return request({
    method: "post",
    url: "/transaction-admin/pay",
    data,
  });
}

export function shippedHistory(data) {
  return request({
    method: "post",
    url: "/transaction-admin/shipped-list",
    data,
  });
}
