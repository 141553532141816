<template>
  <v-sheet>
    <v-row class="pa-3">
      <v-col cols="12" md="9">
        <span class="display-1 font-weight-bold">New Orders</span>
      </v-col>
      <v-col cols="12" md="3">
        <!-- <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          label="Filter"
          dense
          outlined
        /> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mx-2">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :expanded.sync="expanded"
          :server-items-length="total"
          single-expand
          show-expand
          @pagination="pagination"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table class="mt-4">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="width: 200px">Address</td>
                      <td>
                        {{ item.address }}, {{ item.city }},
                        {{ item.province }}, {{ item.country }},
                        {{ item.postal_code }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 200px">Phone Number</td>
                      <td>{{ item.phone }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-simple-table class="my-4">
                <template v-slot:default>
                  <thead>
                    <tr class="font-weight-bold">
                      <td>Name</td>
                      <td>Image</td>
                      <td>Price</td>
                      <td>QTY</td>
                      <td>Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                      <td>{{ d.item.plant }}</td>
                      <td>
                        <v-img
                          :src="d.item.image[0]"
                          class="ma-2"
                          width="80"
                          height="80"
                          @click="
                            () => {
                              viewImage = true;
                              urlFile = d.item.image[0];
                            }
                          "
                        />
                      </td>
                      <td>${{ d.item.price }}</td>
                      <td>{{ d.quantity }}</td>
                      <td>${{ d.price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | dateTimeFormat }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :loading="loadingApprove"
              :disabled="loadingReject"
              :dark="!loadingReject"
              color="#04BF68"
              fab
              depressed
              x-small
              class="mx-2"
              @click="approveOrder(item.id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
              :loading="loadingReject"
              :disabled="loadingApprove"
              color="error"
              fab
              depressed
              x-small
              @click="rejectOrder(item.id)"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import moment from "moment";
import { getNewOrder, approveOrder, rejectOrder } from "@/api/admin/order";

import ViewImage from "../../components/ViewImage.vue";

export default {
  components: {
    ViewImage,
  },
  filters: {
    dateFormat: (value) => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    },
    dateTimeFormat: (value) => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm");
    },
  },
  data() {
    return {
      expanded: [],
      loading: false,
      dialogConfrim: false,
      dialogImage: false,
      dialogVideo: false,
      viewImage: false,
      data: [],
      selectedData: {},
      deleteData: null,
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: 0,
      total: 0,
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Country",
          value: "country",
          sortable: false,
        },
        {
          text: "Order Date",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
          width: 150,
        },
      ],
      query: {
        page: 1,
        limit: 10,
      },
      loadingApprove: false,
      loadingReject: false,
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (parseInt(n) === 0) this.getNewOrder();
    },
  },
  mounted() {
    this.getNewOrder();
  },
  methods: {
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    approveOrder(id) {
      this.loadingApprove = true;
      approveOrder({ id: id })
        .then((res) => {
          const { message, code } = res.data;
          this.snackBar(code === 1, message);
        })
        .catch((e) => console.error("approveOrder()\n", e))
        .finally(() => {
          this.loadingApprove = false;
          this.getNewOrder();
        });
    },
    rejectOrder(id) {
      this.loadingReject = true;
      rejectOrder({ id: id })
        .then((res) => {
          const { message, code } = res.data;
          this.snackBar(code === 1, message);
        })
        .catch((e) => console.error("approveOrder()\n", e))
        .finally(() => {
          this.loadingReject = false;
          this.getNewOrder();
        });
    },
    getNewOrder() {
      this.loading = true;
      getNewOrder(this.query)
        .then((res) => {
          const { code } = res.data;
          if (code) {
            this.total = res.data.data.total;
            const items = res.data.data.data;
            this.data = items.map((e) => {
              const {
                created_at,
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const { id, subtotal, total } = e;
              return {
                created_at,
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                city,
                postal_code,
                province,
                country,
                items: e.item,
                id,
                subtotal,
                total,
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getNewOrder();
    },
  },
};
</script>
