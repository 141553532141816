<template>
  <v-sheet>
    <v-row class="pa-3">
      <v-col cols="12" md="4" class="d-flex align-center">
        <span class="display-1 font-weight-bold">History</span>
      </v-col>
      <!-- <v-col cols="12" md="4">
        <span class="title font-weight-bold">
          Earning in January 2022: $65
        </span>
      </v-col>
      <v-col cols="12" md="4" class="d-md-flex justify-end">
        <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          :class="!$vuetify.breakpoint.xsOnly && 'select-200'"
          label="Month"
          hide-details
          dense
          outlined
        />
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="12" class="mx-2">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :server-items-length="total"
          :expanded.sync="expanded"
          item-key="id"
          single-expand
          show-expand
          @pagination="pagination"
        >
          <template v-slot:[`item.total`]="{ item }">
            ${{ item.total }}
          </template>
          <template v-slot:[`item.shipping_cost`]="{ item }">
            ${{ item.shipping_cost }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table class="mt-4">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="width: 200px">Address</td>
                      <td>{{ item.address }} {{ item.sent_to }}</td>
                    </tr>
                    <tr>
                      <td style="width: 200px">Phone Number</td>
                      <td>{{ item.phone }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="width: 200px">Shipping Cost</td>
                      <td>{{ item.shipping_cost }}</td>
                    </tr>
                    <tr>
                      <td>Handling</td>
                      <td>{{ item.handling }}</td>
                    </tr>
                    <tr>
                      <td>Permit</td>
                      <td>{{ item.premit }}</td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td>{{ item.discount }}</td>
                    </tr>
                    <tr>
                      <td>Bank Fee</td>
                      <td>{{ item.payment_fee }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-simple-table class="my-4">
                <template v-slot:default>
                  <thead>
                    <tr class="font-weight-bold">
                      <td>Name</td>
                      <td>Image</td>
                      <td>Price</td>
                      <td>QTY</td>
                      <td>Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                      <td>{{ d.item.plant }}</td>
                      <td>
                        <v-img
                          :src="d.item.image[0]"
                          class="ma-2"
                          width="80"
                          height="80"
                          @click="
                            () => {
                              viewImage = true;
                              urlFile = d.item.image[0];
                            }
                          "
                        />
                      </td>
                      <td>${{ d.item.price }}</td>
                      <td>{{ d.quantity }}</td>
                      <td>${{ Number(d.item.price) * Number(d.quantity) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import ViewImage from "@/components/ViewImage.vue";
import { shippedHistory } from "@/api/admin/order";
import moment from "moment/moment";

export default {
  components: {
    ViewImage,
  },
  data() {
    return {
      loading: false,
      viewImage: false,
      data: [],
      total: 0,
      selectedData: {},
      form: { category: null },
      urlFile: "",
      viewFile: false,
      query: {
        page: 1,
        limit: 10,
      },
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Resi",
          value: "resi",
        },
        {
          text: "Date",
          value: "date",
        },
      ],
    };
  },
  mounted() {
    this.getShippedHistory();
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getShippedHistory();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getShippedHistory() {
      this.loading = true;
      shippedHistory()
        .then((res) => {
          const { code } = res.data;
          if (code) {
            const { data, total } = res.data.data;
            this.total = total;
            this.data = data.map((e) => {
              const {
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const {
                id,
                subtotal,
                total,
                resi,
                handling,
                premit,
                shipping_cost,
                discount,
                updated_at,
                payment_fee,
              } = e;
              return {
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                sent_to: `${city}, ${postal_code}, ${province}, ${country}`,
                items: e.item,
                id,
                subtotal,
                total,
                shipping_cost,
                handling,
                premit,
                discount,
                payment_fee,
                resi,
                date: moment(updated_at).format("DD MMM YYYY, HH:mm"),
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
