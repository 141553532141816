<template>
  <v-sheet>
    <history v-if="showHistory" @back="showHistory = false" />
    <div v-else>
      <v-row class="pa-3">
        <v-col cols="12" md="8">
          <span class="display-1 font-weight-bold">Ready to Ship</span>
        </v-col>
        <v-col cols="12" md="4" class="d-md-flex justify-end">
          <!-- <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
            depressed
            color="#04BF68"
            dark
            class="mr-2"
            @click="showHistory = true"
          >
            History
          </v-btn> -->
          <!-- <v-select
            :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
            :class="!$vuetify.breakpoint.xsOnly && 'select-200'"
            label="Filter"
            hide-details
            dense
            outlined
          /> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mx-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :loading="loading"
            :expanded.sync="expanded"
            :server-items-length="total"
            single-expand
            show-expand
            @pagination="pagination"
          >
            <template v-slot:[`item.shipping_cost`]="{ item }">
              <v-text-field
                v-model="item.shipping_cost"
                hide-details
                dense
                outlined
                type="number"
                @blur="updateApprove(item)"
              />
            </template>
            <template v-slot:[`item.handling`]="{ item }">
              <v-text-field
                v-model="item.handling"
                hide-details
                dense
                outlined
                type="number"
                @blur="updateApprove(item)"
              />
            </template>
            <template v-slot:[`item.premit`]="{ item }">
              <v-text-field
                v-model="item.premit"
                hide-details
                dense
                outlined
                type="number"
                @blur="updateApprove(item)"
              />
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              <v-text-field
                v-model="item.discount"
                hide-details
                dense
                outlined
                type="number"
                @blur="updateApprove(item)"
              />
            </template>
            <template v-slot:[`item.total`]="{ item }">
              ${{ item.total }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table class="my-4">
                  <template v-slot:default>
                    <thead>
                      <tr class="font-weight-bold">
                        <td>Name</td>
                        <td>Image</td>
                        <td>Price</td>
                        <td>QTY</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                        <td>{{ d.item.plant }}</td>
                        <td>
                          <v-img
                            :src="d.item.image[0]"
                            class="ma-2"
                            width="80"
                            height="80"
                            @click="
                              () => {
                                viewImage = true;
                                urlFile = d.item.image[0];
                              }
                            "
                          />
                        </td>
                        <td>${{ d.item.price }}</td>
                        <td>{{ d.quantity }}</td>
                        <td>
                          ${{ Number(d.item.price) * Number(d.quantity) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :disabled="disableApprove(item)"
                :dark="!disableApprove(item)"
                color="#04BF68"
                fab
                depressed
                x-small
                class="mx-2"
                @click="approveOrder(item.id)"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <view-image
        :viewFile="viewImage"
        :urlFile="urlFile"
        @close="viewImage = false"
      />
      <v-dialog
        v-model="dialogApprove"
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card flat>
          <v-card-text class="pa-4">
            <div class="mb-5 mx-5 text-center subtitle-1">
              <p>Shipping cost have been sent to the customer.</p>
              <p class="font-weight-bold">
                Note: Do not send the package first, before the customer sends
                money to your account.
              </p>
            </div>
            <v-divider class="my-2" />
            <v-row>
              <v-col class="d-flex justify-space-between">
                <v-btn
                  width="150"
                  class="gray"
                  dark
                  depressed
                  @click="dialogApprove = false"
                >
                  Back
                </v-btn>
                <v-btn
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="goToProccessTab"
                >
                  Status Check
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-overlay :value="loadingProccess" z-index="100">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </div>
  </v-sheet>
</template>

<script>
import ViewImage from "../../../components/ViewImage.vue";
import History from "./History.vue";
import { getApprove, updateApprove, processApprove } from "@/api/admin/order";
import { isNumberFloat } from "@/utils/validate";

export default {
  components: {
    ViewImage,
    History,
  },
  data() {
    return {
      expanded: [],
      dialogApprove: false,
      showHistory: false,
      loading: false,
      loadingProccess: false,
      viewImage: false,
      data: [],
      selectedData: {},
      deleteData: null,
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: 0,
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Sent to",
          value: "sent_to",
        },
        {
          text: "Shipping Cost",
          value: "shipping_cost",
          width: 130,
        },
        {
          text: "Handling",
          value: "handling",
          width: 130,
        },
        {
          text: "Permit",
          value: "premit",
          width: 130,
        },
        {
          text: "Discount",
          value: "discount",
          width: 130,
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 100,
        },
      ],
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (parseInt(n) === 1) this.getApproveList();
    },
  },
  mounted() {
    this.getApproveList();
  },
  methods: {
    disableApprove(item) {
      const { shipping_cost, permit, handling } = item;
      return (
        this.loadingProccess &&
        isNumberFloat(shipping_cost) &&
        isNumberFloat(permit) &&
        isNumberFloat(handling)
      );
    },
    goToProccessTab() {
      this.dialogApprove = false;
      this.$router.push({ name: "Orders", query: { tab: 2 } });
    },
    approveOrder(id) {
      this.loadingProccess = true;
      processApprove({ id })
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.snackBar(true, message);
            this.dialogApprove = true;
          } else this.snackBar(false, message);
        })
        .catch((err) => {
          console.error("approveOrder(id)\n", err);
        })
        .finally(() => {
          this.getApproveList();
          this.loadingProccess = false;
        });
    },
    updateApprove(data) {
      const { id, shipping_cost, handling, premit, discount } = data;
      let finalDisc = discount ? discount : 0;
      const body = {
        id,
        data_update: {
          shipping_cost,
          handling,
          premit,
          discount: finalDisc,
        },
      };
      updateApprove(body)
        .then((res) => {
          const { code, message } = res.data;
          if (code) this.snackBar(code === 1, message);
          else this.snackBar(code === 1, message);
        })
        .catch((e) => console.error("updateApprove(data)\n", e))
        .finally(() => this.getApproveList());
    },
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getApproveList();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getApproveList() {
      this.loading = true;
      getApprove(this.query)
        .then((res) => {
          const { code } = res.data;
          if (code) {
            this.total = res.data.data.total;
            const items = res.data.data.data;
            this.data = items.map((e) => {
              const {
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const {
                id,
                subtotal,
                total,
                shipping_cost,
                handling,
                premit,
                discount,
                payment_fee,
              } = e;
              return {
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                sent_to: `${city}, ${postal_code}, ${province}, ${country}`,
                items: e.item,
                id,
                subtotal,
                total,
                shipping_cost,
                handling,
                premit,
                discount,
                payment_fee,
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
