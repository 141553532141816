<template>
  <v-sheet>
    <v-row class="pa-3">
      <v-col cols="12" md="9">
        <span class="display-1 font-weight-bold">Reject Orders</span>
      </v-col>
      <v-col cols="12" md="3">
        <!-- <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          label="Filter"
          dense
          outlined
        /> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mx-2">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :expanded.sync="expanded"
          :server-items-length="total"
          single-expand
          show-expand
          @pagination="pagination"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table class="my-4">
                <template v-slot:default>
                  <thead>
                    <tr class="font-weight-bold">
                      <td>Name</td>
                      <td>Image</td>
                      <td>Price</td>
                      <td>QTY</td>
                      <td>Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                      <td>{{ d.item.plant }}</td>
                      <td>
                        <v-img
                          :src="d.item.image[0]"
                          class="ma-2"
                          width="80"
                          height="80"
                          @click="
                            () => {
                              viewImage = true;
                              urlFile = d.item.image[0];
                            }
                          "
                        />
                      </td>
                      <td>${{ d.item.price }}</td>
                      <td>{{ d.quantity }}</td>
                      <td>${{ d.price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import { rejectListOrder } from "@/api/admin/order";

import ViewImage from "../../components/ViewImage.vue";

export default {
  components: {
    ViewImage,
  },
  data() {
    return {
      expanded: [],
      loading: false,
      dialogConfrim: false,
      dialogImage: false,
      dialogVideo: false,
      viewImage: false,
      data: [],
      selectedData: {},
      deleteData: null,
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: 0,
      total: 0,
      query: {
        limit: 10,
        page: 1,
      },
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
        },
        {
          text: "City",
          value: "city",
          sortable: false,
        },
        {
          text: "Postal Code",
          value: "postal_code",
          sortable: false,
        },
        {
          text: "Province",
          value: "province",
          sortable: false,
        },
        {
          text: "Country",
          value: "country",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getRejectList();
  },
  watch: {
    "$route.query.tab"(n) {
      if (parseInt(n) === 4) this.getRejectList();
    },
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getRejectList();
    },
    getRejectList() {
      this.loading = true;
      rejectListOrder(this.query)
        .then((res) => {
          const { code } = res.data;
          if (code) {
            this.total = res.data.data.total;
            const items = res.data.data.data;
            this.data = items.map((e) => {
              const {
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const { id, subtotal, total } = e;
              return {
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                city,
                postal_code,
                province,
                country,
                items: e.item,
                id,
                subtotal,
                total,
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
