<template>
  <v-sheet>
    <history v-if="showHistory" @back="showHistory = false" />
    <div v-else>
      <v-row class="pa-3">
        <v-col cols="12">
          <span class="display-1 mr-2 font-weight-bold">Proccess Check</span>
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
            :disabled="loadingCheck"
            :dark="!loadingCheck"
            color="blue"
            text
            class="mr-2"
            @click="checkAllPacking"
          >
            <v-icon>mdi-check-all</v-icon>
            Packing
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
            :disabled="loadingCheck"
            :dark="!loadingCheck"
            text
            color="blue"
            class="mr-2"
            @click="checkAllPhyto"
          >
            <v-icon>mdi-check-all</v-icon>
            Phyto
          </v-btn>
        </v-col>
        <!-- <v-col cols="12" md="3" class="d-md-flex justify-end"> -->
        <!-- <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
            depressed
            color="#04BF68"
            dark
            class="mr-2"
            @click="showHistory = true"
          >
            History
          </v-btn> -->
        <!-- <v-select
            :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
            :class="!$vuetify.breakpoint.xsOnly && 'select-200'"
            label="Filter"
            hide-details
            dense
            outlined
          /> -->
        <!-- </v-col> -->
      </v-row>

      <v-row>
        <v-col cols="12" class="mx-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :loading="loading"
            :expanded.sync="expanded"
            :server-items-length="total"
            single-expand
            show-expand
            @pagination="pagination"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="width: 200px">Address</td>
                        <td>{{ item.address }} {{ item.sent_to }}</td>
                      </tr>
                      <tr>
                        <td style="width: 200px">Phone Number</td>
                        <td>{{ item.phone }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="width: 200px">Shipping Cost</td>
                        <td>{{ item.shipping_cost }}</td>
                      </tr>
                      <tr>
                        <td>Handling</td>
                        <td>{{ item.handling }}</td>
                      </tr>
                      <tr>
                        <td>Permit</td>
                        <td>{{ item.premit }}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>{{ item.discount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table class="my-4">
                  <template v-slot:default>
                    <thead>
                      <tr class="font-weight-bold">
                        <td>Name</td>
                        <td>Image</td>
                        <td>Price</td>
                        <td>QTY</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(d, idx) in item.items" :key="`${idx}-item`">
                        <td>{{ d.item.plant }}</td>
                        <td>
                          <v-img
                            :src="d.item.image[0]"
                            class="ma-2"
                            width="50"
                            height="50"
                            @click="
                              () => {
                                viewImage = true;
                                urlFile = d.item.image[0];
                              }
                            "
                          />
                        </td>
                        <td>${{ d.item.price }}</td>
                        <td>{{ d.quantity }}</td>
                        <td>
                          ${{ Number(d.item.price) * Number(d.quantity) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              ${{ item.total }}
            </template>
            <template v-slot:[`item.payment_method`]="{ item }">
              <v-btn text color="blue" @click="showProof(item)">
                {{ paymentText(item) }}
                <span v-if="item.payment_fee" class="ml-2"
                  >(${{ item.payment_fee }})</span
                >
              </v-btn>
            </template>
            <template v-slot:[`item.process_packing`]="{ item }">
              <v-checkbox
                v-model="item.process_packing"
                :disabled="disableCheckbox(item)"
                color="green accent-4"
                @change="changePacking(item)"
              />
            </template>
            <template v-slot:[`item.process_phyto`]="{ item }">
              <v-checkbox
                v-model="item.process_phyto"
                :disabled="disableCheckbox(item)"
                color="green accent-4"
                @change="changePhyto(item)"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :disabled="disableReadyToShip(item)"
                :dark="!disableReadyToShip(item)"
                color="#04BF68"
                fab
                depressed
                x-small
                class="mx-2"
                @click="readyToShip(item)"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <view-proof
        :viewFile="viewImage"
        :urlFile="urlFile"
        :loading="loadingProof"
        @approve="approve()"
        @reject="reject()"
        @close="viewImage = false"
      />
      <v-dialog
        v-model="dialogApprove"
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card flat>
          <v-card-text class="pa-4">
            <div class="mb-5 mx-5 text-center subtitle-1">
              <p>Shipping cost have been sent to the customer.</p>
              <p class="font-weight-bold">
                Note: Do not send the package first, before the customer sends
                money to your account.
              </p>
            </div>
            <v-divider class="my-2" />
            <v-row>
              <v-col class="d-flex justify-space-between">
                <v-btn
                  width="150"
                  class="gray"
                  dark
                  depressed
                  @click="dialogApprove = false"
                >
                  Back
                </v-btn>
                <v-btn
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="goToReadyToShipTab"
                >
                  Ready to Ship
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-sheet>
</template>

<script>
import ViewProof from "./ViewProof.vue";
import History from "./History.vue";
import {
  getProccessList,
  processPacking,
  processPhyto,
  setReady,
  rejectProof,
  approveProof,
} from "@/api/admin/order";
import { isURL } from "@/utils/validate";

export default {
  components: {
    ViewProof,
    History,
  },
  data() {
    return {
      dialogApprove: false,
      showHistory: false,
      loading: false,
      viewImage: false,
      loadingCheck: false,
      data: [],
      selectedData: {},
      deleteData: null,
      form: { category: null },
      urlFile: "",
      itemProof: {},
      viewFile: false,
      tab: 0,
      expanded: [],
      loadingProof: false,
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Proof",
          value: "payment_method",
        },
        {
          text: "Status Invoice Packing",
          value: "process_packing",
          width: 150,
        },
        {
          text: "Status Phyto",
          value: "process_phyto",
          width: 150,
        },
        {
          text: "Ready to Ship",
          value: "action",
          align: "center",
          width: 150,
        },
      ],
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (parseInt(n) === 2) this.getProccessList();
    },
  },
  mounted() {
    this.getProccessList();
  },
  methods: {
    disableCheckbox(item) {
      const { payment_status } = item;
      return !payment_status || this.loadingCheck;
    },
    approve() {
      this.loadingProof = true;
      const { id, payment_method } = this.itemProof;
      approveProof({ id, payment_method })
        .then((res) => {
          const { code, message } = res.data;
          if (code) this.snackBar(code === 1, message);
          else this.snackBar(code === 1, message);
        })
        .catch((e) => console.error("approve()\n", e))
        .finally(() => {
          this.loadingProof = false;
          this.viewImage = false;
          this.itemProof = {};
          this.getProccessList();
        });
    },
    reject() {
      this.loadingProof = true;
      const { id } = this.itemProof;
      rejectProof({ id })
        .then((res) => {
          const { code, message } = res.data;
          if (code) this.snackBar(code === 1, message);
          else this.snackBar(code === 1, message);
        })
        .catch((e) => console.error("approve()\n", e))
        .finally(() => {
          this.loadingProof = false;
          this.viewImage = false;
          this.itemProof = {};
          this.getProccessList();
        });
    },
    paymentText(item) {
      const { payment_method, payment_status } = item;
      return !payment_status
        ? "Waiting Payment"
        : payment_status
        ? payment_method === "paypal"
          ? "PAID Paypal"
          : "PAID Bank Transfer"
        : "Need Approval";
    },
    showProof(item) {
      const { payment_proof, payment_method } = item;
      if (payment_method.toUpperCase() !== "PAYPAL" && isURL(payment_proof)) {
        this.viewImage = true;
        this.urlFile = payment_proof;
        this.itemProof = item;
      }
    },
    disableReadyToShip(item) {
      const { process_phyto, process_packing } = item;
      return this.loadingCheck || !process_packing || !process_phyto;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    goToReadyToShipTab() {
      this.dialogApprove = false;
      this.$router.push({ name: "Orders", query: { tab: 3 } });
    },
    changePhyto(item) {
      const { id, process_phyto, process_packing } = item;
      if (process_packing) {
        this.loadingCheck = true;
        const body = { id: [id], process_phyto };
        processPhyto(body)
          .then((res) => {
            const { code, message } = res.data;
            if (code) {
              this.getProccessList();
              this.snackBar(true, message);
            } else this.snackBar(false, message);
          })
          .catch((e) => console.error("changePhyto(item)\n", e))
          .finally(() => (this.loadingCheck = false));
      } else {
        this.data = [];
        this.getProccessList();
        this.snackBar(false, "Check Packing First");
      }
    },
    changePacking(item) {
      this.loadingCheck = true;
      const { id, process_packing } = item;
      const body = { id: [id], process_packing };
      processPacking(body)
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.getProccessList();
            this.snackBar(true, message);
          } else this.snackBar(false, message);
        })
        .catch((e) => console.error("changePacking(item)\n", e))
        .finally(() => (this.loadingCheck = false));
    },
    checkAllPacking() {
      const id = [];
      this.data.map((e) => {
        if (e.payment_status) id.push(e.id);
      });

      if (id.length > 0) {
        this.loadingCheck = true;
        const body = { id, process_packing: true };
        processPacking(body)
          .then((res) => {
            const { code, message } = res.data;
            if (code) {
              this.getProccessList();
              this.snackBar(true, message);
            } else this.snackBar(false, message);
          })
          .catch((e) => console.error("CheckAllPacking()\n", e))
          .finally(() => (this.loadingCheck = false));
      }
    },
    checkAllPhyto() {
      const id = this.data.filter((e) => e.process_packing).map((e) => e.id);
      if (id.length) {
        this.loadingCheck = true;
        const body = { id, process_phyto: true };
        processPhyto(body)
          .then((res) => {
            const { code, message } = res.data;
            if (code) {
              this.getProccessList();
              this.snackBar(true, message);
            } else this.snackBar(false, message);
          })
          .catch((e) => console.error("CheckAllPhyto()\n", e))
          .finally(() => (this.loadingCheck = false));
      }
    },
    readyToShip(item) {
      this.loadingCheck = true;
      const { id } = item;
      setReady({ id: [id] })
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.getProccessList();
            this.snackBar(true, message);
            this.dialogApprove = true;
          } else this.snackBar(false, message);
        })
        .catch((e) => console.error("readyToShip(item)\n", e))
        .finally(() => (this.loadingCheck = false));
    },
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getProccessList();
    },
    getProccessList() {
      this.loading = true;
      getProccessList(this.query)
        .then((res) => {
          const { code } = res.data;
          if (code) {
            this.total = res.data.data.total;
            const items = res.data.data.data;
            this.data = items.map((e) => {
              const {
                first_name,
                last_name,
                phone,
                address,
                city,
                postal_code,
                province,
                country,
              } = e.shipping_address;
              const {
                id,
                subtotal,
                total,
                handling,
                premit,
                shipping_cost,
                discount,
                process_packing,
                process_phyto,
                payment_method,
                payment_proof,
                payment_status,
                payment_fee,
              } = e;
              return {
                name: first_name.concat(" ").concat(last_name),
                phone,
                address,
                sent_to: `${city}, ${postal_code}, ${province}, ${country}`,
                items: e.item,
                id,
                subtotal,
                total,
                handling,
                premit,
                shipping_cost,
                discount,
                process_packing,
                process_phyto,
                payment_method,
                payment_proof,
                payment_status,
                payment_fee,
              };
            });
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
