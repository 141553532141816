<template>
  <v-dialog
    v-model="viewFile"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    transition="dialog-transition"
  >
    <v-card outlined>
      <v-card-title class="mb-3">
        <div style="position: absolute; right: 25px">
          <v-icon @click="close()"> mdi-close </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" no-gutters>
          <v-img
            v-if="validateImage(urlFile)"
            :src="urlFile"
            contain
            width="100%"
            height="auto"
          />
          <iframe
            v-else
            :src="viewDoc(urlFile)"
            :height="$vuetify.breakpoint.xsOnly ? '700vh' : '500px'"
            width="100%"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="align-start">
        <span v-if="caption" v-html="caption"></span>
        <v-spacer />
        <v-btn
          class="primary btn-action btn-center"
          style="width: 200px"
          dark
          depressed
          @click="openLink(urlFile)"
        >
          <v-icon class="mr-2">mdi-open-in-new</v-icon>
          See Image
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    viewFile: Boolean,
    urlFile: String,
    caption: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    validateImage: (name) => {
      if (/.blob./g.test(name)) return true;
      return /\.(jpe?g|png|gif|bmp|webp)$/i.test(name);
    },
    openLink(url) {
      setTimeout(() => {
        window.open(url, "_blank");
      }, 200);
    },
    viewDoc(name) {
      if (/\.(pdf)$/i.test(name)) {
        return `https://docs.google.com/viewer?url=${name}&embedded=true`;
      } else {
        return `https://view.officeapps.live.com/op/view.aspx?src=${name}`;
      }
    },
  },
};
</script>
