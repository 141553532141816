<template>
  <v-sheet>
    <v-tabs v-model="tab" class="ml-4" @change="tabValue">
      <v-tabs-slider color="#04BF68" />

      <v-tab> New Order </v-tab>
      <v-tab> Approve </v-tab>
      <v-tab> Proccess </v-tab>
      <v-tab> Ready To Ship </v-tab>
      <v-tab> History </v-tab>
      <v-tab> Reject </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <new-orders />
      </v-tab-item>
      <v-tab-item>
        <approve />
      </v-tab-item>
      <v-tab-item>
        <proccess />
      </v-tab-item>
      <v-tab-item>
        <ready-to-ship />
      </v-tab-item>
      <v-tab-item>
        <history />
      </v-tab-item>
      <v-tab-item>
        <reject />
      </v-tab-item>
    </v-tabs-items>
  </v-sheet>
</template>

<script>
import NewOrders from "./NewOrders.vue";
import Approve from "./Approve";
import Proccess from "./Proccess";
import ReadyToShip from "./ReadyToShip/index.vue";
import Reject from "./Reject.vue";
import History from "./History.vue";
export default {
  components: { NewOrders, Approve, Proccess, ReadyToShip, Reject, History },
  data() {
    return {
      tab: this.$route.query.tab | 0,
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    },
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Orders", query: { tab: tab } });
    },
  },
};
</script>
